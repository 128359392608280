import axios from "axios";

function initialState() {
    return {
        infoId: 0,
        infoCnpj: "",
        responsibles: "",
        action: "",
    };
}

export default {
    namespaced: true,
    state: initialState,

    getters: {
        infoId: (state) => state.infoId,
        infoCnpj: (state) => state.infoCnpj,
        responsibles: (state) => state.responsibles,
        action: (state) => state.action,
    },

    mutations: {
        UPDATE_INFO_ID(state, val) {
            state.infoId = val;
        },
        UPDATE_INFO_CNPJ(state, val) {
            state.infoCnpj = val;
        },
        UPDATE_RESPONSIBLES(state, val) {
            state.responsibles = val;
        },
        UPDATE_ACTION(state, val) {
            state.action = val;
        },
    },

    actions: {
        allProspections() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/Prospection")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        saveProspection(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Prospection/Save", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        updateProspection(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Prospection/Update", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        alterStatusProspection(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Prospection/Status", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        searchProviders(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Prospection/Search", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        listResponsible() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/Prospection/Responsible")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        searchKanbanProviders(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Prospection/KanbanSearch", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        // searchProspection(ctx, form) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post("/api/Prospection/Search", form)
        //             .then((response) => resolve(response))
        //             .catch((error) => reject(error.response));
        //     });
        // }
    },
};
